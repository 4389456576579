/**
 * Top level interfaces for module management
 * Begin registering modules here
 */

/**
 * Interface of a UI module that is lazy loaded
 */
export interface IModule {
  // The lazy loaded Component
  readonly component: React.LazyExoticComponent<() => JSX.Element>;
  // The root id that the module is to be loaded onto
  readonly rootId: string;
}

/**
 * Array Interface of React Modules
 */
export type IModules = ReadonlyArray<IModule>;

/**
 * Enum of Modules
 */
export enum ReactModules {
  Demo = "demo",
  SelectedClient = "selected-client",
  Signatories = "erm-signatories",
  DistributionTableConfigure = "distribution-table-configure",
  DistributionTableProposal = "distribution-table-proposal",
  ContactHubCSVUpload = "contacthub-csv-upload",
  Integrations = "integrations",
  ElectronicChecks = "electronic-checks",
  ElectronicChecksBatch = "electronic-checks-batch",
  ToasterApp = "toaster-app",
  LoginApp = "login-app",
  LineItemBulkUpdate = "line-item-bulk-update",
  LineItemSectionUpdate = "line-item-section-update",
}
