/**
 * Import modules here to be loaded
 */

import { lazy } from "react";
import { isProductionEnv } from "utilities/getBuildEnv/getBuildEnv";
import { IModules, ReactModules } from "./interfaces";

/**
 * Lazy load Modules here
 */
const Demo = lazy(() => import("./demo/Demo"));
const ProposalsErmSignatories_Client = lazy(() => import("./proposals-erm-signatories/Client"));
const ProposalsErmSignatories_Signatories = lazy(
  () => import("./proposals-erm-signatories/Signatories")
);
const DistributionTableConfigure = lazy(() => import("./distribution-table/Configure"));
const DistributionTableProposal = lazy(() => import("./distribution-table/Proposal"));
const ContactHubCSVUpload = lazy(() => import("./contacthub-csv-upload/ContactHubCSVUpload"));
const Integrations = lazy(() => import("./integrations/Integrations"));
const ElectronicChecks = lazy(() => import("./electronic-checks/ElectronicChecks"));
const ElectronicChecksBatch = lazy(() => import("./electronic-checks/ElectronicChecksBatch"));
const ToasterApp = lazy(() => import("./toaster-app/app"));
const LoginApp = lazy(() => import("./login-app/app"));
const LineItemBulkUpdateApp = lazy(() => import("./line-item-bulk-update/app"));
const LineItemSectionUpdateApp = lazy(() => import("./line-item-section-update/App"));

/**
 * Add Lazy load components to Modules Array
 */
let Modules: IModules = [
  {
    rootId: ReactModules.SelectedClient,
    component: ProposalsErmSignatories_Client,
  },
  {
    rootId: ReactModules.Signatories,
    component: ProposalsErmSignatories_Signatories,
  },
  {
    rootId: ReactModules.Integrations,
    component: Integrations,
  },
  {
    rootId: ReactModules.DistributionTableConfigure,
    component: DistributionTableConfigure,
  },
  {
    rootId: ReactModules.DistributionTableProposal,
    component: DistributionTableProposal,
  },
  {
    rootId: ReactModules.ContactHubCSVUpload,
    component: ContactHubCSVUpload,
  },
  {
    rootId: ReactModules.ElectronicChecks,
    component: ElectronicChecks,
  },
  {
    rootId: ReactModules.ElectronicChecksBatch,
    component: ElectronicChecksBatch,
  },
  { rootId: ReactModules.ToasterApp, component: ToasterApp },
  { rootId: ReactModules.LoginApp, component: LoginApp },
  { rootId: ReactModules.LineItemBulkUpdate, component: LineItemBulkUpdateApp },
  { rootId: ReactModules.LineItemSectionUpdate, component: LineItemSectionUpdateApp },
];

if (!isProductionEnv) {
  // * If not in production, add demo module
  Modules = [
    {
      rootId: ReactModules.Demo,
      component: Demo,
    },
    ...Modules,
  ];
}

export { Modules };
