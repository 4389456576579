import Box from "carbon-react/lib/components/box";
import Loader from "carbon-react/lib/components/loader";

export const LazyLoadFallback = () => (
  <Box
    display="flex"
    flexDirection="column"
    width={1}
    alignItems="center"
    justifyContent="center"
  >
    <Loader size="large" aria-label="Loading" />
  </Box>
);
