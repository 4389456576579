import CarbonProvider from "carbon-react/lib/components/carbon-provider";
import GlobalStyle from "carbon-react/lib/style/global-style";
import sageTheme from "carbon-react/lib/style/themes/sage";
import { LazyLoadFallback } from "components/UI/LazyLoadFallback/LazyLoadFallback";
import { PropsWithChildren, Suspense } from "react";

export const AllProviders = ({ children }: PropsWithChildren<object>) => {
  return (
    <CarbonProvider validationRedesignOptIn theme={sageTheme}>
      <GlobalStyle />
      <Suspense fallback={<LazyLoadFallback />}>{children}</Suspense>
    </CarbonProvider>
  );
};
