import { lazy } from "react";
import { renderLazyComponent } from "utilities/renderLazyComponent/renderLazyComponent";

// * Lazy import the modules list for local development
const LocalModulesList = lazy(
  () => import("../LocalModulesList/LocalModulesList"),
);

// * expected document id for local mounting
const localDevRootId = "local-root";

/**
 * Mount app for local development
 * @returns
 */
export const RenderLocalDevelopment = () => {
  const el = document.getElementById(localDevRootId);

  if (!el) {
    console.error(`Document id ${localDevRootId} not found.`);
    return;
  }

  renderLazyComponent(LocalModulesList, el);
};
