import { BuildEnvEnum } from "interfaces/BuildEnvEnum";

export const getBuildEnv = () => {
  const { REACT_APP_ENV } = process.env;

  // * if not set, throw error
  if (!REACT_APP_ENV) {
    console.warn(`Build Env not defined. App may not work as expected`);
    return "";
  } else {
    if (Object.values(BuildEnvEnum).includes(REACT_APP_ENV as BuildEnvEnum)) {
      // * Env is correctly defined
      return REACT_APP_ENV as BuildEnvEnum;
    } else {
      // * Env is defined but not an expected value
      console.warn(
        `Unknown env ${REACT_APP_ENV} provided. App may not work as expected`,
      );
      return REACT_APP_ENV;
    }
  }
};

export const isDevEnv = getBuildEnv() === BuildEnvEnum.DEV;
export const isQAEnv = getBuildEnv() === BuildEnvEnum.QA;
export const isStagingEnv = getBuildEnv() === BuildEnvEnum.STAGING;
export const isProductionEnv = getBuildEnv() === BuildEnvEnum.PRODUCTION;
export const isTestEnv = getBuildEnv() === BuildEnvEnum.TEST;
