import { ReactModules } from "modules/interfaces";
import "carbon-react/lib/style/fonts.css";
import "react-toastify/dist/ReactToastify.css";
import { renderModules, unmountModule } from "modules/renderModules";
import { RenderLocalDevelopment } from "components/RenderLocalDevelopment/RenderLocalDevelopment";
import { ERMIdentifiable } from "interfaces/ERMIdentifiable";
import { WPContact } from "interfaces/WPContact";
import { CheckAction } from "modules/electronic-checks/state/CheckActions";
import { BatchContactsAction } from "modules/electronic-checks/state/BatchContactsActions";
import { ElectronicCheck } from "modules/electronic-checks/interfaces/ElectronicCheck";

/**
 * * Define the global expected window values for Typescript (mostly for WP globals)
 */
declare global {
  interface Window {
    readonly pendo: {
      readonly track: (action: string, obj: object) => void;
    };
    readonly featureFlags?: Array<string>;
    readonly amlPermissions?: string;
    readonly ajaxurl?: string;
    readonly jQuery?: unknown;
    readonly wpvars?: {
      readonly nonce?: string;
      readonly tenant_id?: string;
    };
    readonly rest_object?: {
      readonly currency?: string;
    };

    /* readonly */ renderModules: (modules: ReadonlyArray<ReactModules>) => void;
    /* readonly */ unmountModule: (module: ReactModules) => void;
    readonly is_sbc: boolean;
    readonly sfa_client_id?: string;
    readonly duplicateFrom?: {
      readonly client?: ERMIdentifiable;
      readonly primary: ERMIdentifiable;
      readonly additionals: ReadonlyArray<ERMIdentifiable>;
    };
    readonly locale?: string;
    readonly addons?: {
      readonly hasAML?: boolean;
    };
    openCSVImportModal?: () => void;
    validateDistributionTableServices?: () => boolean;
    handleRunNewCheck: (data: WPContact, token: string, wpAppId: string, checkID?: string) => void;
    handleCheckAction: (checkId: string, token: string, action: CheckAction) => void;
    handleBatchContactsAction: (
      token: string,
      action: BatchContactsAction,
      contacts: ReadonlyArray<WPContact>,
      wpAppId: string,
      hideMethod?: () => void
    ) => void;
    handleCheckDetails: (check: ElectronicCheck, viewCheckReport: () => void) => void;
  }
}

/**
 * Catch and suppress ResizeObserver loop limit error
 * Error itself is benign and only occurs in development, not in build
 * Not actually a sign of a broken build but an inability of the browser to deliver all observations in a single animation frame
 * I believe it's a result of the modals and portals used by Carbon as there's a fair few in this build
 * https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
 */
window.addEventListener("error", (e) => {
  if (e.message.includes("ResizeObserver")) {
    const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
    const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute("style", "display: none");
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute("style", "display: none");
    }
  }
});

const { NODE_ENV } = process.env;

if (NODE_ENV === "development") {
  /**
   * When in local development mode, render the modules list
   */
  RenderLocalDevelopment();
} else {
  /**
   * Otherwise, in production / testing
   * Add render module function to the window for later rendering
   */
  window.renderModules = renderModules;
  window.unmountModule = unmountModule;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
