import { IModule, ReactModules } from "modules/interfaces";
import { Modules } from "modules/modules";
import ReactDOM from "react-dom";
import { isProductionEnv } from "utilities/getBuildEnv/getBuildEnv";
import { renderLazyComponent } from "utilities/renderLazyComponent/renderLazyComponent";

/**
 * Main render module function
 * @param {ReadonlyArray<ReactModules>} modules Module array to render
 */
export const renderModules = (modules: ReadonlyArray<ReactModules>) => {
  // * Warn if no modules provided and return
  if (!modules || !modules.length) {
    console.warn("No module names provided");
    return;
  }

  // * Feedback that UI is active and display available module list
  if (modules.length === 1 && modules[0]?.toLowerCase() === "test") {
    console.log("---------");
    console.log("UI renderModules is active.");
    if (!isProductionEnv) {
      // * if not in production env, display availlable modules
      console.log(`Available modules (${Modules.length}):`);
      Modules.forEach((m) => console.log(`${m.rootId}`));
    }
    console.log("---------");
    return;
  }

  // * Warn if any modules requested are not registered
  const validModules = modules.filter((module) => {
    const splitModule = module.split("__")[0];
    if (!splitModule) {
      console.warn(`${module} is not a valid module`);
      return;
    }
    // Check the expected module is registered
    const idx = Modules.findIndex(({ rootId }) => splitModule == rootId);
    if (idx < 0) {
      console.warn(`${splitModule} is not a valid registered module`);
    }
    return idx > -1;
  });

  // * Ensure we have at least one valid module to run
  if (!validModules.length) {
    console.warn("No valid registered modules provided");
    return;
  }

  // For each module being rendered
  modules.forEach((el) => {
    // Check the expected root id is in place
    const ele = document.getElementById(el);
    if (!ele) {
      console.error(`No Document element for ${el}.`);
      return;
    }

    if (ele.childNodes.length > 0) {
      console.error(`Cannot mount as ${el} is not empty.`);
      return;
    }
    const splitModule = el.split("__")[0];
    const module = Modules.find(({ rootId }) => splitModule == rootId) as IModule;

    renderLazyComponent(module.component, ele);
  });
};

export const unmountModule = (module: ReactModules) => {
  const node = document.getElementById(module);
  if (node) {
    ReactDOM.unmountComponentAtNode(node);
  }
};
