import { AllProviders } from "AllProviders";
import { App } from "App";
import React from "react";
import ReactDOM from "react-dom";

export const renderLazyComponent = (
  component: React.LazyExoticComponent<() => JSX.Element>,
  el: HTMLElement
) => {
  ReactDOM.render(
    <React.StrictMode>
      <AllProviders>
        <App Module={component} />
      </AllProviders>
    </React.StrictMode>,
    el
  );
};
